import React from "react";
import _1 from './img/portafolios/1.jpg'
import _2 from './img/portafolios/2.jpg'
import _3 from './img/portafolios/3.jpg'
import _4 from './img/portafolios/4.jpg'
import _5 from './img/portafolios/5.jpg'
import _6 from './img/portafolios/6.jpg'
import _7 from './img/portafolios/7.jpg'
import _8 from './img/portafolios/8.jpg'
import _9 from './img/portafolios/9.jpg'
import _10 from './img/portafolios/10.jpg'
import _11 from './img/portafolios/11.jpg'

export const portfolio = [ _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11 ]