import { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import ReactPlayer from 'react-player'
import axios from 'axios'
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { ParallaxProvider, useParallax, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';
//import AnimatedText from 'react-animated-text-content';

import { EnvelopeFill, Facebook, GeoAlt, Instagram, Linkedin, Palette, TelephoneFill, Tiktok, Whatsapp, Youtube } from 'react-bootstrap-icons';

import { portfolio } from './Portfolio'
import Step from "./Step";
import Slider from "./Slider";
import "./styles.css";

import logo_inmobi_w from './img/logo_inmobi_w.png'
import rensa_w from './img/logo_w.png'
import logo_m from './img/logo_m.png'

import cardonG1 from './img/gallery/cardon_gallery1.jpg'
import cardonG2 from './img/gallery/cardon_gallery2.jpg'
import cardonG3 from './img/gallery/cardon_gallery3.jpg'
import cardonG4 from './img/gallery/cardon_gallery4.jpg'
import cardonG5 from './img/gallery/cardon_gallery5.jpg'
import cardonG6 from './img/gallery/cardon_gallery6.jpg'
import cardonG7 from './img/gallery/cardon_gallery7.jpg'
import cardonG8 from './img/gallery/cardon_gallery8.jpg'
import cardonG9 from './img/gallery/cardon_gallery9.jpg'
import cardonG10 from './img/gallery/cardon_gallery10.jpg'
import cardonG11 from './img/gallery/cardon_gallery11.jpg'
import cardonG12 from './img/gallery/cardon_gallery12.jpg'
import cardonG13 from './img/gallery/cardon_gallery13.jpg'
import cardonG14 from './img/gallery/cardon_gallery14.jpg'
import cardonG15 from './img/gallery/cardon_gallery15.jpg'
import cardonG16 from './img/gallery/cardon_gallery16.jpg'
import cardonG17 from './img/gallery/cardon_gallery17.jpg'
import cardonG18 from './img/gallery/cardon_gallery18.jpg'

import deptDown from './img/dept-down.png'
import deptUp from './img/dept-up.png'
import open from './img/open.png'
import close from './img/close.png'
import logoIcon from './img/logo-icon.png'

import pallete from './img/pallete.png'
import golf from './img/golf.png'
import store from './img/store.png'
import church from './img/church.png'
import forest from './img/forest.png'
import cdmx from './img/cdmx.png'
import logob from './img/logob.png'

import 'animate.css';
import './App.css';

const API_PATH = 'https://residencialcardon.com/mailer.php';
const SITE_KEY = "6LeAxHwiAAAAALLbjz3MEJLq8XvkOQumrvHxfWIv";

const containerStyle = {
  width: '100%',
  height: '350px'
};

const center = {
  lat: 20.9289936,
  lng: -100.7366122
};

const centerMark = {
  lat: 20.9350462,
  lng: -100.7360792
};

function App() {
  const [markerPoint, setMarkerPoint] = useState('');

  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false);
  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/inmobiplace/')
  const [ instaLink, setInstaLink ] = useState('https://www.instagram.com/Inmobiplace/')
  const [ tiktokLink, setTiktokLink ] = useState('https://www.tiktok.com/@inmobiplace')
  const [ linkedLink, setLinkedLink ] = useState('https://www.linkedin.com/company/inmobiplace/?viewAsMember=true')
  const [ youtubeLink, setYoutubeLink ] = useState('https://www.youtube.com/channel/UC5KgahHC2ioFzv4rPdwQvXA')

  const [ goingUp, setGoingUp ] = useState(false);
  const prevScrollY = useRef(0);

  const refHome = useRef(null);
  const refDepts = useRef(null);
  const refGallery = useRef(null);
  const refLocation = useRef(null);
  const refContact = useRef(null);

  const gallery = [ cardonG1, cardonG2, cardonG3, cardonG4, cardonG5, cardonG6, cardonG7, cardonG8, cardonG9, cardonG10,
    cardonG11, cardonG12, cardonG13, cardonG14, cardonG15, cardonG16, cardonG17, cardonG18 ]

  useEffect(() => {
    console.log(portfolio)
    if ( isMobile ) {
      if( isAndroid ) {
        //setFaceLink('fb://page/111933257823824')
      }
      else if( isIOS ) {
        //setFaceLink('fb://profile/111933257823824')
      }
    }

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10)
        document.getElementById("rensa-navbar").className = "nav-bar-custom navbar navbar-expand-lg navbar-dark fixed-top";
      else
        document.getElementById("rensa-navbar").className = "nav-bar-custom-start navbar navbar-expand-lg navbar-dark fixed-top";
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setLoading(false)
        if(isMobile)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setLoading(false)
        if(isMobile)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
        setShow(false);
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

  /*const _handleNext = (currentIndex) => {
    setCurrentIndex(currentIndex + 1);
  };
  const _handleComplete = () => {};
  */

  const handleMarker = () => {
    console.log("click")
    window.open("https://goo.gl/maps/AzrKRz1c2w6ShVai6")
  }

  const onLoad = map => {
    setTimeout(() => {
      setMarkerPoint(<Marker position={centerMark} onClick={ handleMarker }/>)
    }, 1000);
  }

  return (
    <div>
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPrivacy} size="lg" onHide={ () => setShowPrivacy(false) }>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#000", paddingLeft: "34px" }}>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", textAlign: "justify" }}>
          Por medio del presente aviso GRUPO MEBS SA DE CV con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en posesión de particulares le informa y hace de su conocimiento que es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección con las siguientes finalidades: proveer la prestación de servicios y productos que solicite, notificarle sobre promociones y novedades en cuanto a servicios y productos relacionados con los ya contratados o que sean contratados o adquiridos posteriormente, evaluar la calidad del servicio que brindamos y en general para dar cumplimiento a las obligaciones que hemos contraído con usted. Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
          <br/><br/>
          Nombre<br/>
          Número te lefónico <br/>
          Correo electrónico <br/>
          <br/><br/>
          Es importante hacerle de su conocimiento que en cualquier momento tiene el derecho de acceso, rectificación y cancelación de sus datos personales así como a revocar el consentimiento que nos ha otorgado para los fines señalados con anterioridad por lo que es necesario envíe una solicitud al número telefónico de contacto 56 2598 5051 o vía correo electrónico en contacto@inmobiplace.com, aplica para el caso de no desear recibir mensajes promocionales.
          <br/><br/>
          Fecha de actualización: 10/05/2022
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShowPrivacy(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPortfolio} size="xl" onHide={ () => setShowPortfolio(false)}>
        <Modal.Header closeButton>
          <Modal.Title><div className='section-title'>Portafolio</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel controls={true} indicators={false} interval={ null } pause={ false }>
            { portfolio.map(item => (
              <Carousel.Item>
                <img className="d-block w-100" src={ item } />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      <Navbar id="rensa-navbar" collapseOnSelect expand="lg" variant="dark" className="nav-bar-custom-start" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <img alt="" id="brand_image" src={logo_m} width="auto" height="30" className="d-inline-block align-top brand-image-start" onClick={() => handleNavClick("refHome")}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav onSelect={handleNavClick}>
              <Nav.Link eventKey="refHome">
                <div className="nav-link-custom">INICIO</div>
              </Nav.Link>
              <Nav.Link eventKey="refDepts">
                <div className="nav-link-custom">DEPARTAMENTOS</div>
              </Nav.Link>
              <Nav.Link eventKey="refGallery">
                <div className="nav-link-custom">GALERÍA</div>
              </Nav.Link>
              <Nav.Link eventKey="refContact">
                <div className="nav-link-custom">CONTACTO</div>
              </Nav.Link>
              <Nav.Link eventKey="refLocation">
                <div className="nav-link-custom">UBICACIÓN</div>
              </Nav.Link>
            </Nav>
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <Nav.Link  onClick={ () =>  window.open('https://wa.me/5215625985051') }>
                <Whatsapp className="icons-style"/>&nbsp;&nbsp;
                <span className="nav-link-custom"> 56 2598 5051 </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="home-section" ref={ refHome }>
        <Row className="align-items-center">
          <ReactPlayer className="player-v" url='https://youtu.be/z00huBmZI-g' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ true }/>
        </Row>
      </Container>

      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce" animateOut="animate__fadeOut">
        <Container className="section">
          <Row>
            <div className="section-title" align="center">Espacio ideal para vivir</div>
          </Row>
          <Row>
            <Col md={ 6 } xs={ 12 }>            
              <div className="section-desc">
                <br/>
                El proyecto cuenta con 2 torres, cada una con 4 exclusivos departamentos diseñados con la mejor arquitectura y amplios espacios de aparcamiento, integrando modernidad y una infraestructura de primer nivel con el toque característico de esta mágica y hermosa ciudad.
                <br/><br/>
                Cada torre dispone de un patio trasero exclusivo para los residentes de planta baja y un Roof Garden para quienes elijan los departamentos de planta alta.
              </div>
            </Col>
            <Col md={ 6 } xs={ 12 } className="form-container">
              <div className="section-desc">
                <br/>
                Estos espacios están diseñados con el objetivo de tener áreas de descanso para poder disfrutar de un momento agradable.
                <br/><br/>
                El concepto de este proyecto se enfoca en brindar lujo y confortando como resultado un proyecto armónico y completo.
                <br/><br/>
                Residencial Cardón refleja el balance perfecto para que sus residentes vivan en armonía.
              </div>
            </Col>
          </Row>
        </Container>
      </AnimationOnScroll>

      <div className="section container-depts" ref={ refDepts }>
        <Container className="section-depts">
        <Row>
          <div>
            <div className="section-title" align="center">Departamentos</div>
          </div>
        </Row>
        <Row>
          <Col md={ 6 } xs={ 12 } className="p-30">            
            <div><img src={ deptDown } className="img-100" /></div>
            <hr />
            <div>
              <div className="depts-title">
                Departamentos Planta Baja
              </div>
              <div>
                <div className="depts-subtitle">PREVENTA $3,610,000 / 102 m<sup>2</sup></div>
                <div className='depts-list'>
                  <br/>
                  <ul>
                    <li>Sala</li>
                    <li>Comedor</li>
                    <li>Cocina</li>
                    <li>Cuarto de servicio</li>
                    <li>Recámara principal con vestidor y baño completo</li>
                    <li>Recámara de visitas 1</li>
                    <li>Recámara de visitas 2</li>
                    <li>Baño completo</li>
                    <li>Acceso a jardín privado</li>
                  </ul>
                </div>
              </div>
            </div>

          </Col>
          <Col md={ 6 } xs={ 12 } className="p-30">
            <div><img src={ deptUp } className="img-100" /></div>
            <hr />
            <div>
              <div className="depts-title">
                Departamentos Planta Alta
              </div>
              <div>
                <div className="depts-subtitle">PREVENTA $3,950,000 / 104 m<sup>2</sup></div>
                <div className='depts-list'>
                  <br/>
                  <ul>
                    <li>Sala</li>
                    <li>Comedor</li>
                    <li>Cocina</li>
                    <li>Cuarto de servicio</li>
                    <li>Recámara principal con vestidor y baño completo</li>
                    <li>Recámara de visitas 1</li>
                    <li>Baño completo</li>
                    <li>Terraza cubierta</li>
                    <li>Roof Garden</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={ 1 } xs={ 2 } style={{ position: "relative" }}>
            <span><img className='depts-comma-open' src={ open }/></span>
          </Col>
          <Col md={ 10 } xs={ 8 }>
          <div className="depts-legend">
            <span>Todo el proyecto está diseñado con los más altos estándares de calidad, empleando el uso de materiales y acabados de lujo, como: mármol, madera, cantera, entre otros, con el fin de obtener un mayor nivel de eficiencia dentro de la construcción.</span>
          </div>
          </Col>
          <Col md={ 1 } xs={ 2 } style={{ position: "relative" }}>
            <span><img className='depts-comma-close' src={ close }/></span>
          </Col>
        </Row>
        <br/><br/>
        </Container>
      </div>

      <Container className="section" ref={ refGallery }>
        <Row>
          <div align="center">
            <div className="title-line" align="center">
              <hr className="hr-title"/>
            </div>
          </div>
        </Row>
        <Row>
          <div>
            <div className="section-title" align="center">Galería</div>
            <br/>
            <br/>
          </div>
        </Row>
        <Row>
          <Col md={ 12 }>
            <Container>
              <Row>
                <Carousel interval={ 7000 }>
                  { gallery.map( item => (
                    <Carousel.Item>
                      <div className="portfolio-gall-container">
                        <div className="portfolio-gall-cont-back" style={{ backgroundImage: 'url("' + item + '")' }}>
                          <img src={ item } className="portfolio-gallery-img" style={{ visibility: 'hidden' }} />
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className="section pt-0" ref={ refContact }>
        <Row>
          <div align="center">
            <div className="title-line" align="center">
              <hr className="hr-title"/>
            </div>
          </div>
        </Row>
        <Row>
          <div>
            <div className="section-title" align="center">¡Contacta con nosotros!</div>
            <br/>
            <br/>
            { isMobile ? <></> : <br/> }
          </div>
        </Row>
        <Row>
          <Container>
            <Row>
              <Col md={ 6 }>
                <div align="center">
                  <img src={ logoIcon } className="contact-img"/>
                  <br/>
                  <br/>
                </div>
                <div align="center">
                  <div className='icons-contact'>Escríbenos</div>
                  <div  style={{ cursor: "pointer" }} onClick={  () => window.open("https://wa.me/5215525985051")  }>
                    <Whatsapp className="icons-contact"/>&nbsp;&nbsp;
                    <span className="text-contact"> 56 2598 5051 </span>
                  </div>
                  <div style={{ cursor: "pointer" }} onClick={  () => window.open("mailto:contacto@inmobiplace.com")  }>
                    <EnvelopeFill className="icons-contact"/>&nbsp;&nbsp;
                    <span className="text-contact"> contacto@inmobiplace.com </span>
                  </div>

                  <br/>
                  <div className='icons-contact'>Llámanos</div>
                  <div style={{ cursor: "pointer" }} onClick={  () => window.open("tel:5528391785")  }>
                    <TelephoneFill className="icons-contact"/>&nbsp;&nbsp;
                    <span className="text-contact"> 55 2839 1785 </span>
                  </div>

                  <br/>
                  <br/>
                  <br/>
                </div>
              </Col>
              <Col md={ 6 }>
                <div className='slide-form'>
                  <div align='center' className='form-logo-container'>
                    <img src={ logob } className='form-logo'/>
                  </div>
                  <Form id="contactForm" className="contactForm" onSubmit={handleOnClick}>
                    <Form.Group>
                      <Form.Label className="label-custom">Nombre completo</Form.Label>
                      <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="label-custom">Correo Electrónico</Form.Label>
                      <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="label-custom">Teléfono</Form.Label>
                      <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="label-custom">Mensaje</Form.Label>
                      <Form.Control required name="comments" as="textarea" value={comments} rows="2" onChange={ (e) => setComments(e.target.value) } />
                    </Form.Group>

                    <div align="center">
                      <br/>
                      <Button variant="outline-primary" disabled={ isLoading } type="submit">
                        <span>{isLoading ? 'ENVIANDO…' : 'ENVIAR'}</span>&nbsp;
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>

      <Container className="section pt-0 mp-20" ref={ refLocation }>
        <Row>
          <Col>
            <div>
              <div className="section-title" align="left">
                Ubicación
              </div>
              <br/>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={ 7 }>
            <GeoAlt className="icons-maps" />&nbsp;&nbsp;&nbsp;
            <span className='maps-title'>C. PARAÍSO, 37735 COLONIA SAN LUIS REY, GTO.</span>
            <div>Su ubicación facilita el acceso a comercios, servicios, centros educativos, así como de entretenimiento.</div>
            <br/>
            <br/>
            <div className='map'>
              <LoadScript googleMapsApiKey="AIzaSyAlRrxNFLSDHNRgRUNAjDocYC8No8NZWL4">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={ 14 }
                  onLoad = { onLoad }
                >
                  { markerPoint }

                </GoogleMap>
              
              </LoadScript>
            </div>
            <br/>
            <br/>
          </Col>
          <Col md={ 5 }>
            <div>
              <div className='ref-title'>
                Puntos de referencia
              </div>
              <br/>
              <br/>
              <Container>
                <Row className="ref-row">
                  <Col md={ 2 }> </Col>
                  <Col md={ 2 } align="center">
                    <img src={ pallete } className='ref-icon' />
                  </Col>
                  <Col md={ 8 }>
                    <div className="ref-text">Fábrica "La Aurora"</div>
                    <div className="ref-desc">A 7 minutos</div>
                  </Col>
                </Row>

                <Row className="ref-row">
                  <Col md={ 2 }> </Col>
                  <Col md={ 2 } align="center">
                    <img src={ golf } className='ref-icon' />
                  </Col>
                  <Col md={ 8 }>
                    <div className="ref-text">Club de Golf "Ventanas de San Miguel"</div>
                    <div className="ref-desc">A 8 minutos</div>
                  </Col>
                </Row>

                <Row className="ref-row">
                  <Col md={ 2 }> </Col>
                  <Col md={ 2 } align="center">
                    <img src={ church } className='ref-icon' />
                  </Col>
                  <Col md={ 8 }>
                    <div className="ref-text">Parroquia "San Miguel Arcángel"</div>
                    <div className="ref-desc">A 15 minutos</div>
                  </Col>
                </Row>

                <Row className="ref-row">
                  <Col md={ 2 }> </Col>
                  <Col md={ 2 } align="center">
                    <img src={ store } className='ref-icon' />
                  </Col>
                  <Col md={ 8 }>
                    <div className="ref-text">Plaza "La Luciérnaga"</div>
                    <div className="ref-desc">A 18 minutos</div>
                  </Col>
                </Row>

                <Row className="ref-row">
                  <Col md={ 2 }> </Col>
                  <Col md={ 2 } align="center">
                    <img src={ forest } className='ref-icon' />
                  </Col>
                  <Col md={ 8 }>
                    <div className="ref-text">Jardín Botánico "El Charco del Ingeniero"</div>
                    <div className="ref-desc">A 18 minutos</div>
                  </Col>
                </Row>

                <Row className="ref-row">
                  <Col md={ 2 }> </Col>
                  <Col md={ 2 } align="center">
                    <img src={ cdmx } className='ref-icon' />
                  </Col>
                  <Col md={ 8 }>
                    <div className="ref-text">CDMX</div>
                    <div className="ref-desc">A 3 horas y media</div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>

      </Container>

      <div className="footer full-container">
        <Container style={ !isMobile ? { width: "90%" } : { width: "100%" } }>
          <Row className="align-items-center">
            <Col md={ 4 } xs={ 12 } align="center">
              <img src={ logo_m } width="auto" height="30"/>
              <div className='footer-text' style={{ paddingTop: '10px' }} onClick={ () => setShowPortfolio(true) }>
                Portafolio
              </div>
              <br/>
            </Col>
            <Col md={ 4 } xs={ 12 } align="center">
              <Container>
                <Row>
                  <Col xs={ 6 } onClick={ () => window.open( 'https://rensaarquitectura.com/' ) }>
                    <div className='footer-text'>DESARROLLADO POR:</div>
                    <div><img src={ rensa_w } className="footer_logo"/></div>
                  </Col>
                  <Col xs={ 6 } onClick={ () => window.open( 'https://inmobiplace.com/' ) }>
                    <div className='footer-text'>COMERCIALIZADO POR:</div>
                    <div><img src={ logo_inmobi_w } className="footer_logo"/></div>
                  </Col>
                </Row>
              </Container>
              <br/>
            </Col>
            <Col md={ 4 } xs={ 12 } align="center">
              <div>
                <Instagram className='footer-icon' onClick={ () => window.open( instaLink ) }/>&nbsp;&nbsp;
                <Facebook className='footer-icon' onClick={ () => window.open( faceLink ) }/>&nbsp;&nbsp;
                <Tiktok className='footer-icon' onClick={ () => window.open( tiktokLink ) }/>&nbsp;&nbsp;
                <Linkedin className='footer-icon' onClick={ () => window.open( linkedLink ) }/>&nbsp;&nbsp;
                <Youtube className='footer-icon' onClick={ () => window.open( youtubeLink ) }/>
              </div>
              <div className='footer-text' style={{ paddingTop: '10px' }} onClick={ () => setShowPrivacy(true) }>
                Aviso de Privacidad
              </div>
            </Col>
          </Row>
        </Container>        
      </div>
    </div>
  );
}

export default App;
